import Vue from 'vue'
import App from './App.vue'
import 'document-register-element/build/document-register-element'
import VueRouter from 'vue-router'



import axios from 'axios'
import VueAxios from 'vue-axios'

import * as Fingerprint2 from 'fingerprintjs2'
Vue.prototype.$fingerprint = Fingerprint2;

var VueCookie = require('vue-cookie');
Vue.use(VueCookie);

Vue.use(VueAxios, axios)

// import './assets/css/tailwind.css'

import Hotjar from 'vue-hotjar'
Vue.use (Hotjar, {
    id: '2259440' // Hotjar Site ID
})

Vue.use(VueRouter);

const routes = [
]

const router = new VueRouter({
    mode: 'history' ,
    routes // short for `routes: routes`
})


App.router = router;


import vueCustomElement from 'vue-custom-element'
Vue.use(vueCustomElement)
Vue.customElement('hey-user', App, {
    shadow: true,
    beforeCreateVueInstance(root) {
        const rootNode = root.el.getRootNode();

        if (rootNode instanceof ShadowRoot) {
            root.shadowRoot = rootNode;
        } else {
            root.shadowRoot = document.head;
        }
        return root;
    },
    // you can set shadow root for element. Only works if native implementation is available.
    // shadow: true,
    connectedCallback() {
        // Add external script for building widgets
        // If ?build='heyuser' query params in added in url
        // let params = (new URL(document.location)).searchParams;
        // let name = params.get('build'); // is the string "Jonathan Smith".
        // if(name ==='heyuser'){
        //     const script = document.createElement('script');
        //     script.setAttribute('src', 'https://heyuser-build.netlify.app/nuxt-custom-elements/heyuser-build/heyuser-build.js');
        //     script.setAttribute('type', 'text/javascript');
        //     document.getElementsByTagName('head')[0].appendChild(script);
        //     // Add a tag
        //     const wrapper = document.createElement("heyuser-build");
        //     document.body.appendChild(wrapper);
        // }

    },
    constructorCallback() {
    },

})

Vue.config.productionTip = false

// new Vue({
//   render: h => h(App),
// }).$mount('#app')
