<template>
  <div>
    Hi from iframe
  </div>
</template>


<script>
import Vue from 'vue'
export default {
  name: 'assist',
  data() {
    return {
      iApp: null,
    }
  },
  computed: {
  },
  render(h) {
    return h('iframe', {
      on: {
        load: this.renderChildren
      }
    })
  },
  watch: {
  },
  beforeUpdate() {
    // this.iApp.children = Object.freeze(this.$slots.default)
  },
  methods: {
    renderChildren() {
      console.log('hi')
      const children = this.$slots.default
      const body = this.$el.contentDocument.body

      const el = document.createElement('div') // we will mount or nested app to this element
      body.appendChild(el)

      const iApp = new Vue({
        name: 'iApp',
        data() {
          return {
            children: Object.freeze(children)
          }
        },
        render(h) {
          return h('div', this.children)
        }
      })
      iApp.$mount(el)
      this.iApp = iApp
    }
  }
}
</script>